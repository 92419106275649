.cs-history {
    padding: 25px;
    font-family: 'Public Sans', sans-serif;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
}

.timeline {
    list-style: none;
    padding: 20px 0;
    position: relative;
}

.timeline:before {
    background-color: #eee;
    bottom: 0;
    content: " ";
    left: 20px;
    margin-left: -1.5px;
    position: absolute;
    top: 0;
    width: 3px;
}

.timeline > li {
    margin-bottom: 20px;
    position: relative;
}

.timeline > li:after,
.timeline > li:before {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li > .timeline-panel {
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    margin-left: 65px;
    padding: 20px;
    position: relative;
}

.timeline > li > .timeline-panel:before {
    border-color: transparent #ccc;
    border-style: solid;
    border-width: 15px 15px 15px 0;
    content: " ";
    display: inline-block;
    left: -15px;
    position: absolute;
    top: 26px;
}

.timeline > li > .timeline-panel:after {
    border-color: transparent #fff;
    border-style: solid;
    border-width: 14px 14px 14px 0;
    content: " ";
    display: inline-block;
    left: -14px;
    position: absolute;
    top: 27px;
}

.timeline > li > .timeline-badge {
    background-color: #fff;
    border: 3px solid #eee;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    color: #ddd;
    font-size: 1.4em;
    height: 50px;
    left: 20px;
    line-height: 50px;
    margin-left: -25px;
    position: absolute;
    text-align: center;
    top: 16px;
    width: 50px;
    z-index: 1;
}

.timeline > li > .station-done {
    background-color: #2cb054;
}

.timeline > li > .station {
    line-height: 43px;
    font-size: 14px;
}

.timeline > li > .timeline-badge .glyphicon {
    left: -1px;
    right: -1px;
    top: 0;
}

.timeline > li > .timeline-badge .glyphicon.glyphicon-ok {
    left: 0;
}

.timeline-title {
    color: inherit;
    margin-top: 0;
}

.status.label {
    font-weight: 400;
    text-transform: uppercase
}

.timeline-heading h4 svg {
    vertical-align: middle;
    margin: 0 6px;
}

.timeline-heading h4 {
    margin: 0;
}

.label {
    border-radius: .25em;
    color: #fff;
    display: inline;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    padding: .2em .6em .3em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
}
.label-type {
    font-size: 12px;
    display: block;
    color: #84bccf;
    margin-bottom: 5px;
}
.label-quantity {
    background: #949494;
}
.label-pending {
    background-color: #777
}

.label-confirmed,.label-in-transit,.label-passed-stopover,.label-picked-up {
    background-color: #007a9d
}

.label-approved,.label-completed,.label-delivered,.label-delivery-completed,.label-dropped-off,.label-ordered {
    background-color: #00b1e1
}

.label-delivery-unconfirmed {
    background-color: #ffa040
}

.label-clarify-1st-pick-up,.label-clarify-approved,.label-clarify-confirmed,.label-clarify-delivery,.label-clarify-lmc-scan,.label-clarify-ordered,.label-clarify-pickup,.label-exception,.label-investigate-incomplete-delivery {
    background-color: #ff5a40
}

.label-closed,.label-closed-canceled,.label-closed-incomplete-delivery,.label-closed-with-error,.label-closed-without-delivery {
    background-color: #2cb054
}
